<template>
  <div>
    <template v-if="infos && infos.length">
      <span v-for="(info, index) in infos" :key="info.id">
        <el-descriptions :style="isHandle ? style1 : null">
          <span slot="title">
            报价单信息
            <span v-if="index === 0 && info.status === 'NEGOTIATING' && info.negotiatingType === 'ROLLBACK'" style="color: #FAB007; font-size: 12px;">
              <i class="el-icon-warning-outline"></i>回退原因：{{ info.rollbackReason }}
            </span>
            <span v-if="index === 0 && info.status === 'NEGOTIATING' && info.negotiatingType === 'UPDATE'" style="color: #FAB007; font-size: 12px;">
              <i class="el-icon-warning-outline"></i>修改报价：询单业务员已修改报价，请确认报价。如有疑问请联系{{ contactInfo.name }}/{{ contactInfo.phone }}
            </span>
          </span>
          <el-descriptions-item label="供应商名字">{{ defaultFormat(info.tenantName) }}</el-descriptions-item>
          <el-descriptions-item label="报价轮次">{{ defaultFormat(info.versionNo) }}</el-descriptions-item>
          <el-descriptions-item label="报价单号">{{ defaultFormat(info.code) }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{ formatDate(info.createTime) }}</el-descriptions-item>
          <el-descriptions-item label="交付方式">{{ defaultFormat(info.deliveryMethodDesc) }}</el-descriptions-item>
          <el-descriptions-item label="创建人">{{ defaultFormat(info.createUserName) }}</el-descriptions-item>
          <el-descriptions-item label="联系电话">{{ defaultFormat(info.contactPhone) }}</el-descriptions-item>
          <el-descriptions-item label="计划交付时间">{{ formatDate(info.plannedDeliveryDate)
            }}</el-descriptions-item>
          <el-descriptions-item label="是否含备用机">{{ info.containSpareMachineFlag ? '是' :
            info.containSpareMachineFlag
              === false
              ? '否' : '--' }}</el-descriptions-item>
          <el-descriptions-item label="保修期">{{ `${defaultFormat(info.warrantyPeriod)}${defaultFormat(info.warrantyPeriodUnitName)}` || '--' }}</el-descriptions-item>
          <el-descriptions-item label="发票类型">{{ defaultFormat(info.invoiceTypeDesc) }}</el-descriptions-item>
          <el-descriptions-item label="税率">{{ defaultFormat(info.taxRate) }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions :style="isHandle ? style2 : null">
          <el-descriptions-item label="报价备注">{{ defaultFormat(info.quotePriceRemark) }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions :style="isHandle ? style2 : null">
          <el-descriptions-item label="报价附件">
            <div v-for="img in (info.inquireQuotePriceAnnexList || [])" :key="img.url" class="fileContainer">
              <el-link type="primary" :href="img.url" :underline="false" target="blank"> <i class="el-icon-document"
                  style="color:#FF5959;margin-right:4px;"></i>{{ img.annexName
                }}</el-link>
            </div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :style="isHandle ? style3 : null">
          <el-descriptions-item label="检测结果">{{ defaultFormat(info.detectionResult) }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="报价明细" :colon="false" class="device-table" :style="isHandle ? style4 : null"
          :labelStyle="{ margin: 0 }">
          <el-descriptions-item>
            <xz-table ref="table" :columns="quotePricecolumns" :data="getQuotePriceData(info, index)"
              :scroll="{ x: '100%', y: 600 }">
              <span slot="money" slot-scope="text, record">
                <span :class="{ negotiating: index === 0 && info.status === 'NEGOTIATING' && info.negotiatingType === 'UPDATE' && !record.isEqual}">{{ text }}</span>
              </span>
            </xz-table>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="备件清单" :colon="false" class="device-table" :style="isHandle ? style5 : null"
          :labelStyle="{ margin: 0 }">
          <el-descriptions-item>
            <xz-table ref="table" :columns="columns" :data="info.itemList" :scroll="{ x: '100%', y: 600 }">
              <span slot="qualityAssPeriod">
                {{ `${info.qualityAssPeriod || ''}${info.qualityAssPeriodUnitName || ''}` || '--' }}
              </span>
              <span slot="quantity" slot-scope="text, record">
                {{ `${text}${record.unitName}` || '--' }}
              </span>
              <span slot="price" slot-scope="text, record">
                <span :class="{ negotiating: index === 0 && info.status === 'NEGOTIATING' && info.negotiatingType === 'UPDATE' && (record.lastPrice !== text)}">{{ text }}</span>
              </span>
            </xz-table>
          </el-descriptions-item>
        </el-descriptions>
      </span>
    </template>
    <el-empty v-else style="height: 100%;" description="暂无数据" :image="require('@/assets/empty.png')"></el-empty>
  </div>
</template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import * as Moment from 'dayjs';
import { inquireQuotePriceHistoryQuotePriceList } from '@/api/inquiryForm.js';

export default {
  components: {
    xzTable,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    isHandle: {
      type: Boolean,
      default: false,
    },
    contactInfo: {
      type: Object,
      default: () => ({
        name: '',
        phone: '',
      }),
    },
  },
  watch: {
    id: {
      handler (val) {
        if (val) {
          this.getDetail();
        }
      },
      immediate: true,
    },
  },
  data () {
    return {
      columns: [
        {
          title: '备件编码',
          dataIndex: 'code',
          key: 'code',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '备件名称',
          dataIndex: 'name',
          key: 'name',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '备件型号',
          dataIndex: 'model',
          key: 'model',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '操作内容',
          dataIndex: 'operationTypeDesc',
          key: 'operationTypeDesc',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '原厂/兼容',
          dataIndex: 'compatibilityRequirementDesc',
          key: 'compatibilityRequirementDesc',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '质保期',
          dataIndex: 'qualityAssPeriod',
          key: 'qualityAssPeriod',
          width: 120,
          ellipsis: true,
          scopedSlots: { customRender: 'qualityAssPeriod' },
        },
        {
          title: '数量（单位）',
          dataIndex: 'quantity',
          key: 'quantity',
          width: 120,
          ellipsis: true,
          scopedSlots: { customRender: 'quantity' },
        },
        {
          title: '单价（元）',
          dataIndex: 'price',
          key: 'price',
          width: 120,
          ellipsis: true,
          scopedSlots: { customRender: 'price' },
        },
        {
          title: '金额（元）',
          dataIndex: 'rowAmount',
          key: 'rowAmount',
          width: 120,
          ellipsis: true,
          scopedSlots: { customRender: 'rowAmount' },
        },
      ],
      quotePricecolumns: [
        {
          title: '计费项目',
          dataIndex: 'name',
          key: 'name',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '金额（元）',
          dataIndex: 'money',
          key: 'money',
          width: 120,
          ellipsis: true,
          scopedSlots: { customRender: 'money' },
        },
        {
          title: '合计（元）',
          dataIndex: 'total',
          key: 'total',
          width: 120,
          ellipsis: true,
          customRender: (text, row, index) => {
            const obj = {
              children: row.totalIsEqual ? <span>{text}</span> : <span style="color: #fab007">{text}</span>,
              attrs: {},
            };

            if (index === 0) {
              obj.attrs.rowSpan = 3;
            } else {
              obj.attrs.rowSpan = 0;
            }

            return obj;
          },
        },
      ],
      infos: [],
      style1: {
        'margin-top': '8px',
        'padding': '18px 18px 0',
        'border-bottom-left-radius': 0,
        'border-bottom-right-radius': 0,
      },
      style2: {
        'padding': '0 18px 0',
        'border-radius': 0,
      },
      style3: {
        'padding': '0 18px 0',
        'border-radius': 0,
      },
      style4: {
        'padding': '18px 18px 0',
        'border-radius': 0,
      },
      style5: {
        'padding': '18px 18px 0',
        'border-radius': 0,
        'border-bottom-left-radius': '6px',
        'border-bottom-right-radius': '6px',
      },
    };
  },
  methods: {
    formatDate (text) {
      return text ? Moment(text).format('YYYY-MM-DD HH:mm:ss') : '';
    },
    defaultFormat (v) {
      return v || v === 0 ? v : '--';
    },
    getDesc (key) {
      return this.info?.deviceList?.[0][key] || '--';
    },
    getDetail () {
      inquireQuotePriceHistoryQuotePriceList({
        inquirePriceId: this.id,
        findNewestFlag: true,
        orderBy: [
          {
            orderByProperty: 'versionNo',
            orderByType: 'DESC',
          },
        ],
      }).then(({ body }) => {
        this.infos = body || [];
      });
    },
    getQuotePriceData (info, index) {
      const total = (info.labourAmount || 0) + (info.sparePartAmount || 0) + (info.transportationAmount || 0);
      const preCheck = !index && info.status === 'NEGOTIATING' && info.negotiatingType === 'UPDATE';

      return [
        {
          name: '人工',
          isEqual: preCheck ? info.lastLabourAmount === info.labourAmount : true,
          money: info.labourAmount || info.labourAmount === 0 ? info.labourAmount.toLocaleString() : '--',
          totalIsEqual: preCheck ? info.labourAmount === info.lastLabourAmount && info.sparePartAmount === info.lastSparePartAmount && info.transportationAmount === info.lastTransportationAmount : true,
          total: total ? total.toLocaleString() : '--',
        },
        {
          name: '备件',
          isEqual: preCheck ? info.sparePartAmount === info.lastSparePartAmount : true,
          money: info.sparePartAmount || info.sparePartAmount === 0 ? info.sparePartAmount.toLocaleString() : '--',
          totalIsEqual: true,
          total: '',
        },
        {
          name: '运费及其它',
          isEqual: preCheck ? info.transportationAmount === info.lastTransportationAmount : true,
          money: info.transportationAmount || info.transportationAmount === 0 ? info.transportationAmount.toLocaleString() : '--',
          totalIsEqual: true,
          total: '',
        },
      ];
    },
  },
};
</script>
<style scoped lang="scss">
/deep/.el-descriptions {
  background-color: #fff;
  border-radius: 6px;

  .el-descriptions__header {
    margin-bottom: 10px;
  }

  &.device-table {
    .el-descriptions__header {
      margin-bottom: 0px;
    }

    .el-descriptions-item__content {
      overflow: auto;
    }
  }

  .negotiating {
    color: #FAB007;
  }
}
</style>
